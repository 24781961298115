/*
* 업무구분:
* 화면 명:MSPSA061M
* 화면 설명:화상상담
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" title="화상상담" type="subpage" :show-title="false" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="headerviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_ClickBtnPrevious()" />
              <div slot="nav" class="icon-wrapper" @click="fn_ClickBtnPrevious()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '화상상담 현황' : '화상상담 현황'}}</div>
              <div slot="action" @click="fn_CustNmSearch"><mo-icon>search</mo-icon></div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template v-if="isSearch" #collapsing>
            <ur-box-container alignV="start"  direction="column" class="pt1"> 
              <!-- 검색결과 영역 : 검색하고 돌아오면 나오게 해주세요-->
              <ur-box-container alignV="start"  direction="column" class="search-result-box bd-b-Ty1"> 
                <strong class="tit">검색결과</strong>
                <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
                  <mo-tab-label idx="1">
                    <span class="txt">{{searchCustNm}}</span> 
                  </mo-tab-label>
                  <!-- <mo-tab-label idx="2">
                    <span class="txt">서가망</span> 
                  </mo-tab-label> -->
                </mo-tab-box>
              </ur-box-container>
            </ur-box-container>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <!-- sum -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum bd-b-Ty1">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <div>
                      <span class="sum">총<span class="count"> {{ showCtNofacCnslListVO.length}}</span>명</span>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- sum end --> 
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container v-if="!isNodata" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb82">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list list-item-p2024">
              <!-- <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-radio-list2 type2 list-item-p2024"> -->
                <mo-list :list-data="showCtNofacCnslListVO">
                  <template #list-item="{item}">
                    <mo-list-item :class="{'on' : item.value1 === true}" class="bd-b-Ty1">
                      <mo-checkbox v-model="selectItem" @input="fn_selectedCustNm" :value="item.nofacCnslId"  class="mr10 msp-radio" size="medium"/>
                      <!-- <mo-checkbox v-model="selectItem" @input="fn_selectedCustNm" :value="item.nofacCnslId" class="mr10" size="medium"/> -->
                      <div class="list-item__contents">
                        <div @click="fn_PopupCustNmUiCard(item.chnlCustId)" class="list-item__contents__title">
                          <span class="name txtSkip">{{item.custNm}}</span>
                        </div>
                        <div class="sub-text">
                          <div class="">
                            <span class="crTy-bk1">{{`${item.age}세`}}</span><em>|</em><span class="crTy-bk1">{{ item.sexCd }}</span>
                          </div>
                          <div class=" crTy-bk7">
                            <span class="fs14rem">필수컨설팅동의
                              <b class="fs16rem ml10" :class="[Number(item.mcnstRmdrDds) > 0 ?'crTy-blue4' : 'crTy-orange2']">{{Number(item.mcnstRmdrDds) > 0 ? 'Y' : 'N' }}</b>
                            </span><em>|</em>
                            <span class="fs14rem">마케팅동의
                              <b class="fs16rem ml10" :class="[Number(item.mktRmdrDds) > 0 ?'crTy-blue4' : 'crTy-orange2']">{{Number(item.mktRmdrDds) > 0 ? 'Y' : 'N' }}</b>
                            </span>
                          </div>
                          <div class=" crTy-bk7">
                            <span class="fs14rem">상담자료</span><span @click="fn_nofacCnslYmdClick(item)" class="crTy-bk1 fs16rem ml10 underline">{{item.nofacCnslYmd}}</span>
                          </div>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>
            </ur-box-container>
            <!-- no-data --> 
            <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="no-data">
              <mo-list-item ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <span class="ns-ftcr-gray"><p class="mt20">화상상담 내역이 없어요.</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container> 
          </template>

        </mo-collapsing-header-view>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative mb60">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_nofacCnslBtnClick">화상상담</mo-button>
          </div>
        </ur-box-container>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import DateUtil from '@/ui/ps/comm/PSDateUtil'
  import CustInfoBottomSheet from '@/ui/cm/CustInfoBottomSheet' // 고객카드
  import Msg from '@/systems/webkit/msg/msg'
  import MSPSA060P from '@/ui/sa/MSPSA060P'
  import MSPSA062P from '@/ui/sa/MSPSA062P'
  import MSPSA063P from '@/ui/sa/MSPSA063P'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA061M",
    screenId: "MSPSA061M",
    components: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)
      this.fn_selNofacCnslHistory() // 상담이력관리 조회
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA061M')
      //footer bottom 영역
      this.$BottomManager.fn_SetBottomVisible(true)
      // 스크롤 애니메이션 instance 선언
      this.headerviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.headerviewScrollCompID).addEventListener('scroll', this.$refs.headerview.onScrollEvent)
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBtnPrevious)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      ctNofacCnslListVO: [], // 상담현황 리스트
      showCtNofacCnslListVO: [], // 화면에 노출될 상담현황 리스트
      searchCtNofacCnslListVO: [], // 검색 상담현황 리스트
      lv_NofacCnslCust: {}, // 고객정보
      isMSPSA060P: {}, // 고객검색
      isMSPSA062P: {}, // 화면내 고객검색 팝업
      isMSPSA063P: {}, //
      isCustInfoBottomSheet: {}, // 고객카드
      searchCustNm: '', // 검색고객명
      searchKeywordOrigin: '',
      isNodata: true, // 데이터가 존재하지 않습니다.
      isSearch: false, // 검색 여부
      selectItem: '', // 선택한 고객
      selectList: {}, // 선택한 고객 데이터모음
      headerviewScrollCompID: '',
      }
    },
    watch: {
      showCtNofacCnslListVO() {
        if (this.showCtNofacCnslListVO.length > 0) {
          this.isNodata = false
        } else {
          this.isNodata = true
        }
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_selNofacCnslHistory
      * 설명       : 상담이력관리 조회
      ******************************************************************************/
      fn_selNofacCnslHistory () {
        let lv_Vm = this
        this.ctNofacCnslListVO = []
        let pParams = {
          nofacCnsltNo: lv_Vm.getStore('userInfo').getters.getUserInfo.userId
        }
        let trnstId = 'txTSSCT84S1'
        let auth = 'S'

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (lv_Vm.$commonUtil.isSuccess(response)) {
             let t_ctNofacCnslListVO = response.body.ctNofacCnslListVO
              if (t_ctNofacCnslListVO.length !== 0) {
                for (let i = 0; i < t_ctNofacCnslListVO.length; i++) {
                  lv_Vm.isNodata = false
                  let tempObj = {}
                  tempObj.nofacCnslId = t_ctNofacCnslListVO[i].nofacCnslId // 상담ID
                  tempObj.chnlCustId = t_ctNofacCnslListVO[i].nofacChnlCustId // 고객채널ID
                  tempObj.custNm = t_ctNofacCnslListVO[i].nofacChnlCustNm // 고객명
                  tempObj.age = DateUtil.getInsuranceAge(t_ctNofacCnslListVO[i].nofacCustRrnFront, moment(new Date().toISOString()).format('YYYYMMDD')) // 보험나이
                  let sexCd = lv_Vm.$bizUtil.getGenderFromKnb(t_ctNofacCnslListVO[i].nofacCustRrnFront)
                  tempObj.sexCd = sexCd === 'male' ? '남' : '여' // 성별
                  tempObj.dob = moment(lv_Vm.$bizUtil.getBirthDateFromKnb(t_ctNofacCnslListVO[i].nofacCustRrnFront)).format('YYYY-MM-DD') // 생년월일
                  tempObj.mcnstRmdrDds = t_ctNofacCnslListVO[i].mcnstRmdrDds // 필수컨설팅
                  tempObj.mktRmdrDds = t_ctNofacCnslListVO[i].mktRmdrDds // 마케팅
                  tempObj.nofacCnslYmd = moment(t_ctNofacCnslListVO[i].nofacCnslStrDtm.substring(0, 8)).format('YYYY-MM-DD') // 화상상담
                  lv_Vm.ctNofacCnslListVO.push(tempObj)
                }
                lv_Vm.showCtNofacCnslListVO = _.cloneDeep(lv_Vm.ctNofacCnslListVO)
                console.log('showCtNofacCnslListVO>>>>', lv_Vm.showCtNofacCnslListVO)
              } else {
                lv_Vm.isNodata = true // 데이터가 존재하지 않습니다.
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_CustNmSearch
      * 설명       : 고객검색
      ******************************************************************************/
      fn_CustNmSearch() {
        this.isMSPSA062P = this.$bottomModal.open(MSPSA062P, {
                  properties: {
                  },
                  listeners: {
                    onPopupConfirm: (param) => {
                      console.log('검색한 고객명>>>>', param)
                      this.searchCustNm = param !== '' ? param : '전체'
                      this.isSearch = true
                      this.fn_searchRslt(this.searchCustNm)
                      this.$bottomModal.close(this.isMSPSA062P);
                    },
                    onPopupClose: () => {
                      this.$bottomModal.close(this.isMSPSA062P);
                    }
                  }
                })
      },
      /******************************************************************************
      * Function명 : fn_searchRslt
      * 설명       : 고객검색결과
      ******************************************************************************/
      fn_searchRslt(param) {
        this.searchCtNofacCnslListVO = []
        this.selectItem = ''
        if (param !== '전체') {
          this.searchCtNofacCnslListVO = this.ctNofacCnslListVO.filter(item => item.custNm.indexOf(param) > -1)
          console.log('검색결과>>>>', this.searchCtNofacCnslListVO)
          this.showCtNofacCnslListVO = this.searchCtNofacCnslListVO
        } else {
          this.showCtNofacCnslListVO = this.ctNofacCnslListVO
        }
      },
      /******************************************************************************
      * Function명 : fn_ClickBtnPrevious
      * 설명       : 뒤로가기 , 검색 후 뒤로가기
      ******************************************************************************/
      fn_ClickBtnPrevious() {
        console.log('이동>>>')
        if (this.isSearch) { // 검색 한 경우
          this.isSearch = false
          this.selectItem = ''
          this.showCtNofacCnslListVO = this.ctNofacCnslListVO
        } else {
          console.log('메인이동')
          this.fn_goMain()
        }
      },
      /******************************************************************************
      * Function명 : fn_nofacCnslYmdClick
      * 설명       : 상담자료 날짜 클릭
      ******************************************************************************/
      fn_nofacCnslYmdClick(param) {
        console.log('상담자료 날짜 클릭 > 상담ID >>>', param.nofacCnslId)
        this.isMSPSA063P = this.$bottomModal.open(MSPSA063P, {
                  properties: {
                    pNofacCnslCust: param,
                    pNofacCnslId: param.nofacCnslId,
                  },
                  listeners: {
                    onPopupConfirm: () => {
                      this.$bottomModal.close(this.isMSPSA063P);
                    },
                    onPopupClose: () => {
                      this.fn_clear()
                      this.$bottomModal.close(this.isMSPSA063P);
                    }
                  }
                },
                {
                  properties: {
                    noHeader: true
                  }
                })
      },
      /******************************************************************************
      * Function명 : fn_nofacCnslBtnClick
      * 설명       : 화상상담 버튼 클릭
      ******************************************************************************/
      fn_nofacCnslBtnClick() {
        console.log('버튼클릭<<>>', this.selectItem)
        console.log('버튼클릭11<<>>', _.isEmpty(this.selectItem))
        if (!_.isEmpty(this.selectItem)) {
          this.isMSPSA063P = this.$bottomModal.open(MSPSA063P, {
                    properties: {
                      pNofacCnslCust: this.selectList,
                    },
                    listeners: {
                      onPopupConfirm: () => {
                        this.$bottomModal.close(this.isMSPSA063P);
                      },
                      onPopupClose: () => {
                        this.fn_clear()
                        this.$bottomModal.close(this.isMSPSA063P);
                      }
                    }
                  },
                  {
                    properties: {
                      noHeader: true
                    }
                  })
        } else {
          this.isMSPSA060P = this.$bottomModal.open(MSPSA060P, {
                    properties: {
                    },
                    listeners: {
                      onPopupConfirm: () => {
                        this.$bottomModal.close(this.isMSPSA060P);
                      },
                      onPopupClose: () => {
                        this.$bottomModal.close(this.isMSPSA060P);
                      }
                    }
                  },
                  {
                    properties: {
                      noHeader: true
                    }
                  })
        }
      },

      /******************************************************************************
      * Function명 : fn_PopupCustNmUiCard
      * 설명       : 고객 네임카드 오픈
      ******************************************************************************/
      fn_PopupCustNmUiCard (pChnlCustId,pGubun) {
        //to-do 고객네임카드 연계 화면 처리
        if(pChnlCustId !== '-'){
          this.isCustInfoBottomSheet = this.$bottomModal.open(CustInfoBottomSheet, {
            properties: {
              chnlCustId: pChnlCustId,
              cnstlNo: this.getStore('userInfo').getters.getUserInfo.userId,
              //contNo : contNo
              parentId: 'MSPPS230M'
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(this.isCustInfoBottomSheet)
              },
              onPopupClose: () => {
                this.$bottomModal.close(this.isCustInfoBottomSheet)
              }
            }
          })
        }
      },
      /******************************************************************************
      * Function명 : fn_selectedCustNm
      * 설명       : 라디오 버튼 고객선택
      ******************************************************************************/
      fn_selectedCustNm() {
        this.selectList = {}
        //this.selectList = this.showCtNofacCnslListVO.filter(item => item.nofacCnslId.indexOf(this.selectItem) > -1)
        this.showCtNofacCnslListVO.forEach(item => {
          if (item.nofacCnslId === this.selectItem) {
            this.selectList = item
          }
        })

        console.log('선택<<<>>>>', this.selectList)
      },
      /******************************************************************************
      * Function명 : fn_clear
      * 설명       : 초기화
      ******************************************************************************/
     fn_clear() {
       this.selectItem = ''
     },

      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        this.$router.push({ name: 'MSPBC002M' })
      }
    }
  };
</script>
<style scoped>
</style>