/*
* 업무구분:
* 화면 명: MSPSA062P
* 화면 설명: 화상상담 현황 고객검색
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" title="고객검색" :show-title="true" :topButton="false" @on-header-left-click="fn_close">
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap">
      <div class="con-area mt30">
        <mo-text-field ref="custNmInput" v-model="custNmData" class="form-input-name full" underline clearable placeholder="고객명을 입력하세요" />
      </div>


    </ur-box-container>

    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_clear">초기화</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_search">검색</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import DateUtil from '@/ui/ps/comm/PSDateUtil'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA062P",
    screenId: "MSPSA062P",
    components: {
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right', // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA062P')
      this.$refs.custNmInput.focus()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      custNmData: '',
      isDisable: true, // 검색버튼 비활성화 여부
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      popup() {
        console.log('test')
      },
      /******************************************************************************
      * Function명 : fn_clear
      * 설명       : 초기화 버튼 클릭
      ******************************************************************************/
      fn_clear() {
        this.custNmData = ''
      },
      /******************************************************************************
      * Function명 : fn_close
      * 설명       : 뒤로가기버튼(팝업닫기)
      ******************************************************************************/
      fn_close() {
        this.$emit('onPopupClose')
      },
      /******************************************************************************
      * Function명 : fn_search
      * 설명       : 검색 버튼 클릭
      ******************************************************************************/
      fn_search() {
        this.$emit('onPopupConfirm', this.custNmData)
      }
    }
  }
</script>
<style scoped>
</style>