import { render, staticRenderFns } from "./MSPSA062P.vue?vue&type=template&id=60c7ca81&scoped=true&"
import script from "./MSPSA062P.vue?vue&type=script&lang=js&"
export * from "./MSPSA062P.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c7ca81",
  null
  
)

export default component.exports